
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexAO8DtRrZAq_459OTQJNaJSuna_bRRXUuQ9QEV0z13XHOIMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/index.vue?macro=true";
import { default as login_45R3SloL7UrfxSVVC9CZgn64yzLphdKZFwL7xxu_45EP7kMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifyo6urkCa5RZdm8jcyH5_45IrJhLUuPEW0Vnvp4O_45zxo9TAMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemaps_45_pLTiQ4udK_4Hoii43GEWql1wm9_mKUj5OZy3beNkMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45uskG8D1mZw2PJkW_oRHeKQ_45df0MM7SGxYMMV5PO_452KMDoMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerP542S7Otxy38ykHPMSs7noOY6UHQkO_45bBU28olkTVr0Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaints242boGm0vnS8VNNnvmxlaVD3SBMNFkc5xsYY_45BgbmecMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usH20husPU5g7IQauwRUaGXv8xURNTPVhIMPxbyEfdUroMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexYdmukM_45rRNDUP6OF7QY2YmANjoqJ8S982SYmbKKfZFIMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as index_6sDD_45CejK_45KLQLvkDD1cjDIXkFycYa9IzoMMoVWWLcMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93OIm5jA_nZwqFF6P0w_Vmr1NzM_45xI0Ihq629qf3EHZw0Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsSs5I8fxZDe_45SRWfgkW9UYJ5Ye7lnJS2_45E7ONWMgmi1YMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45use24Jkj8A8xGj_DiER5Wp9Hvke8O7O_450rBJVRiC_45v5DoIMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexBMhBUt_eQJ8UUWfu4PA_GIu2_O39lve6NUipAfQS3RcMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policyNyOP_45lF4_pjTFQ4fdE4SyJIAxrM2k2VRPe1OZNViyVMMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordS8Ehl5iW9A2tv9zMCuv4P_NZGKbg1dbSXGUr7bNXwIgMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexmLKKSktdHa6v7Uxlb9dqDKMDrN9Qg5GW_45vLh2s4Cja4Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexBM6gM6MNwYH6OYudxwSaRbm1eYeKLa1B8leHaYenssQMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordVyJSWavjAexCPirqdemYD_MOixdMih1_45gj4Qwaqz_45msMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as errorsZpDKXlm3_45pTwJSO0wWOblGrtzyOq_45F9AJrENENk7QsMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/cdr/error.vue?macro=true";
import { default as indexJWetMT3KzxBBXe6iTbl8NrK2I5eQGUPofnUzgiSbigoMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93be9GvsuMkOT5izDbKh4PFsbpzKOLAcStyBHOLPFPZnIMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guides_45bYOOG8z6Dyx72jEESjTN8zO_lwKWVUGr76LXY_45DBZYMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticecAGd_45S2xK1c2V3QmXuZFVgjnHpQ98k9J8t_45GHLfzdT4Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexbbJqby5ZPSS4Ue3izgNNu51oJJ3gI0fDkIpAf5RVsZIMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordEaDnJLL_452_BBkNPjf1yPVDVK7m37baFa_mE_454G3YoiIMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45back0W90_NV_45VKKryW3bos3_45QidGG9L41JYGisU9iJP_bWoMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergeaaHMfC1diSLTJYg5Cn1kKStaYzOKRxKQOgngX1_WiIEMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as index5U3O_45aitvTeWp1iVqEokd8OeFQc5QSi9liMlZbjY7m8Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93KYd2hbPAn_45sTCg4_91UnKAOVeRDesIA9JOGj8bTG5P8Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youwzZ08sLEaTK02HlHwCzBZROCyxgK2C_6AilsE_SSCNQMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as thank_45you60oibOffpZ5sSSa_45E0w3d2T2rwYlTeySqfeHr_zgc10Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/cdr/thank-you.vue?macro=true";
import { default as indexCeKm4xggfl_LC2exIxJ7YjsFjUeeooQJAVPCt481iIoMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexgm2nx5X_gqmt9nLXQR_nboOCrPhiKAxFhT6erDDdANAMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93UKbY6yqDqMf8iSogT_45WVAzZJEc44bQowQ13p5Nc0moIMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexqV2__45UFSnb0gUa8IHuiCJoPFIYbjqKF68V0vsPe6FCcMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexsmLqaTTmpqwBZUrFK2GfUjXolLsewLxvXReylkU33_kMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93vVQkFLlS8qUmEvik8Ob7GMp1gkWzxr2tePxqcYomZM0Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callback2kwfCxb8yqDF_45UisIRev4ICu_KEJYW8gjw_453PScDC18Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimcDD2JwUY43F9z4994LN3wtowld8C9gTLYpNayx0xPt0Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as index3qqB85IYMhooVRm8Zh4rDIYJqH0Xkd6TRbAWUOmVTygMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youHidZ4xk5w1vX8JIeNiZT_9APAFJ82ssdDbg3sg7203IMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorYb70Z4MkT_457Cpt8guitdj_PkR2FNbwPizQRCthJl91MMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexDYAFPddvu22O4kdL8wrcDqhzUnPIqyyZqSnW1ruJEK0Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexk3mBVeUmZghLtj2H_EItHhzzP5cM7RVFSTGmvRxnf7sMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as errorftrQ_273QFuniGnvwF8cVa__45wXmzfA5DhrRWykZeGWoMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93dNY0zU8A1bcaB6KNcBq4SbHM9xifoulq1jV7OhVT5zQMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsfu1JC16KhSncnZJ6fB1uquxpA6_45KZprEzX83dUi_45jxEMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesxUPUozWr0Ny0UcUjLvSgSJx57xGvhlTkoFqI7Q_UgYAMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93G1lYoX1MocyMqj_45uXqeUTW7iWSFYNUU2j0zsAHBFsncMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexiDCF15C_45DAtlM4ZhkJBWWb_45hFPTOXdQTmoYdcvIDUFgMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingsFT82KTdDxAt6NUdhJtgu7wt104KFN8PGBKZVVz6vCXQMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexb_455FBGrqYGdPFBswpaURc_45RgW5yCbBWcs4OA3D40IP4Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chatkxEV_45Gk4vpZwHlg10KzSj6KoEVqmHliAd9KE9ZmvWRgMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45saleexo9gzWqMuMxVvSeXN6aFYuilplCkOer_b4UGAMBEdsMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youZNMceRJKGVUekbiWkDmcCgu5wU20ah5AOoYMGp73P5sMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensiveiKizUN7xcAYy4x5O8zesw0UCBOT4f514Spe8vwfH6bYMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93xx_6IJWvGCvjrtEzKkDuVKnagUIho2BNheI249ZJP4MMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addZyGXLs4Lhq8XwVgFnQVNcaSnaOgbgMJ2cXmYGRaLzF0Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as index1TVBRdh7U8v5ZZ0k6hqMIQhmOvkCsADDjF_AqjQOGQcMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93WxNcdaZNzy0X_0_45vsWPpHqcn12EZXdEa8qWRaKdC_455MMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackoGAN_45nr_450HzCuzquPl4OYwf29FQpWgaVTRaW7A_VZ0wMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93HJUuuTaplWPiMS661319f_45_4578bahrM4P8kWC1OfwJqgMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youZbEmZ2GDPsp2bb9sYjuCER5SR74k1pZAfMz8BgITuGEMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45b_gMGTwf4sEuYNQHuRXddiICQfawbmtRVNQlVanmEeMMMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexta7yVAo1ANOJxf5qQy_45N4HH6XULmO7pwvRcOuROJ0J4Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93s1BIFUm423aidDUNTKFuDyhjuzeuJqEr_EyYXmnsOBIMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93ik624wVT4yATkwf8x5elG6kyRpoS7y8OuJAlvtUR0dgMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexfJjCPxpeIVgatOI73wVb808IdtSj6KCb4iSVXkMGK2cMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addf6c5V3K8qE8zjOkTA1yEIHHOZDIoupi2SSwSj_45nOojsMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93hepViPzYIS2Ftv0FMptH_45sx9c1o3b8z64x9EUehuMNIMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsc9p7YnU6NRsRRMCqCIv2m2rdf0nRaA3sU5WP7dWda7kMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as callbackyke6nmVa0wpC4JzLKydsAKt6vfIJbUmg6B_7sgWztbwMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/callback.vue?macro=true";
import { default as digital_45eic9ThnmbsiiJ29k7JUtQ1kj_sKwsEOfWQVQnI5Kq2fTbkMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youME9oS8gZW7rM9BKp8pinGmZecCKivDeobvzX7mAIZyAMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93vI0PSEyi6wCAWfToFpLpRAFRfP8dBcvu9k8XBCGlx_4Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93PHjiUz57XXxHeTxOuHgzHj7RVuMkzLJD5VIGg3IuOYAMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as comparisonFBqvdY2l1wlEEjH_w4yQUWAjnskHzB6D8LVjE10pf3IMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/comparison.vue?macro=true";
import { default as step1YYLxCCT3UDQaGVqcxJRaMcJgPIa0UtaeSahzF_mZOlcMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as detailsmNc0oDWO1lrEeB5eLgX4qdclVGpDiuI9wTsHZim5Cl0Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as results4uBVEwi0a1wJavJKoBnbn0ESkXrfIlZVoOdijS5lFtQMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexjs07UpDrt2_45hHRmpvhh0jNr2N9l_but_jBYM4nPFUicMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signupJp_nG_u_jTz7qZnmS8nbEcMWx10rp1Z_45JR0gXv7z3MYMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youM6UaahleQNSMKp7_45_451H8OI5iwn2zWguWpAsAjCZmc_450Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93vQgVCPd9OnqF9Iw2ntLCc_VUmyU6ksmpph8UM2McA64Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93ey_OoYplfsRea2sEK3oNP0WzUH4fSfz4vnHieJht1nEMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0MvndQTEGxkQuAB8pVqA_45VkpDRcIYyAtyFkKDDAkj8l4Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customers2lZTndcSGFojoHlpSpQ_45RsjDYd9GunHA0elL7TnLDZUMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45yousFOpmuLT9h9IrLRWiHkwgVEjp6lEhXkVxXeC9458xUcMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsnJNK_wUJSMzzZspWc2pe1qRByin4scd_VIj_45CnE37jkMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as details0y2u318xVYC5sKq9sA7RVKTGAMsh0zvSA1esx6tkPJwMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93hZzslhjjpB6iNrDCQxQKaoPCX3Nw1EVbzG2Knp8UHH4Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebate6wN2uQyBWJz26reBgUZZikHoZ5LBvnNZSm9__450U_3N4Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_930F0G5EE1U2VX6zEZ_wzIhpHyIJNjGTsFsFm3JGMtBVgMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsw5aMBFs9XKMHGxfFvGhvRdBnGT3RkPg21HOzYuFqV98Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0YPo8O8CLSwBHCnWhCbatM1hffk3Te2L0rHSerhRyo5sMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexxfQuGhzxLO8_onN7vZWD34XhJSZfaJ52qls9Tu4_45UZ8Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexq_45RHE7vUuBGPRAj1jLcmeUBI0tOLJltZ2_HnVFyd5xAMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as details81zDTtR6Lg6FHLT0ysuKqVNTps7qhpyfBrUPUnmMDX0Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsD_fmleKcuUnydvnFpDowly7TqP1W4iko7ufvVbXKM5sMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as _91step_93Yz0Q4JMwiHtxAtYmiFCCTnvqlGfYYAHsMYacL5t_45Z7kMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue?macro=true";
import { default as indexMbv881N1Q9MTDy2xOtgaIYQosbXHmU8hqKJ3zQEjRXcMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailsULdM9lKh1Hnw2E25UZxkwV9S6oIURMWQ_4zOhnl9C0IMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as index3yuSo2VrgE5IaSNtBP78vgJC5b62UUfpDjkyNoBVDOUMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successB3j_45ykkz1aGWyNoDgLIJdhhJgouJsn6hfiMzsLkIiJgMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offereg6cyPRsSLVilKVqpZup1qciBUWzqvRC_s6mpDNlm8kMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsZPROAgIwQ_w5TlHiU8nk9Vjc_45_45SSXHAOmPsAvwMJIGoMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbour2vZgd1lJ1GBAQ4ZeJYWAgaUIQ_6i0GqpduM6OmuPNYgMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93UImFYwmGxTtNN_RCQNASfUivRCl_45bjniTMIPonaav8kMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexl1r3u9UYf_45CS76CslqHq2Hm0xQ4AZe2xK26HunChxKgMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93CyRFtyMDZKLJgtMwdACHPib0GBD9oCQUkFNFY2w_bqQMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexyE1DvXlvJDDuoP_FaEkxsz5uT4zUQhudMMYW6AFudZIMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93UKTcaY_ekGnN8c3hAs_45_45TP4ZfyOETEwTSIvwpK8NaGUMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as thank_45youxI5mw3TtNfewG2bzWnkP8dvjqX_T9L_45g0OXJHNes3TQMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue?macro=true";
import { default as index9agxhp_9CQZEXqF9hTmQyF2XhnqIpQrssI3B8f_6YDwMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as index1QP_45v2jX5u4kKOAklIGYchAb5RpXKPwoxbDRisfzAagMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsjHq1B19wENFFWZDStRKB2T_45EHxAPU_V21x_45XqqqqtsQMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expired1oLI5uIi_45glc6kU04gl8kROxk2JUrjWp_45v3bnWLKglYMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexCcIqt_HefWAg8ZyNg15qEOCuWG31qHOAe4f3mbI7Y6EMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youXqNna3UNkU_TozGEPk663PcTiMOCGm_45ZHCt6RDPpLx4Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as details_457Nd1WPP_9LoOsC678rQl0lda6p9bjBLeuc1oZKVnvsMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as pre_45assesmentM3US8Hk4u9bNcC2Oj1uglmDptf5ozxUlMmrY0D28q0gMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue?macro=true";
import { default as confirmationAGEZNr9KrutGDselqD5hRgq08q9BpY68sQWrejrH_45dsMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionXp8lAuN0EsPSTY6fmPTY7tc5vXRJkgRaUsY_WRuYdJAMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsS0RK8bRywa8Vsf9uW90ekIg3bGB2UC4x4ZgonMhVpAAMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationl29NOFz0hJEE5gZLszmqWbybJAIU0K42vnWVQh8olnkMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93FYVftT_45__h_XBRaJHJZP9qZjKL2haacF9k8iw8mxY7IMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93_zfQE4jjBBNdic6ENMBUESKrLWWIbtXvERudfKaWtCYMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93dhnGRGM3y7ndykqUTlDk6_lZHGbiZyF5aZ_ePiI7hcwMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_932enlsdn6zTv7Hl1zL9lq6SNizhdZ2463nt_zmgCp8GEMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as indexQHkgXM0b9pXVU0op_bcRIW1__450497HK1vBHXOruSMGYMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailsD7q4FgZYpCNri2ys1ZadGoy8q3QOcntnQj_KBQzMqP8Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93M32FDKPnhi9PajSphId3GUIG0Qpzsok5lOEanp13PsoMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_930Vvgda1W4O_Ieg4zLttUveCJ6nNcURZyEW4gAE4a4PIMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexW0giImPrEnDafThUZTw2UFUajMxkJkbZQWeus92t6TUMeta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as indexUASzAufyc3YhT34zherdKrmT4Ud4wEgrFKn_0PCQfL8Meta } from "/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login_45R3SloL7UrfxSVVC9CZgn64yzLphdKZFwL7xxu_45EP7kMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerP542S7Otxy38ykHPMSs7noOY6UHQkO_45bBU28olkTVr0Meta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordS8Ehl5iW9A2tv9zMCuv4P_NZGKbg1dbSXGUr7bNXwIgMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexBM6gM6MNwYH6OYudxwSaRbm1eYeKLa1B8leHaYenssQMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordVyJSWavjAexCPirqdemYD_MOixdMih1_45gj4Qwaqz_45msMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "energy-cdr-error",
    path: "/energy/cdr/error",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/cdr/error.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordEaDnJLL_452_BBkNPjf1yPVDVK7m37baFa_mE_454G3YoiIMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93KYd2hbPAn_45sTCg4_91UnKAOVeRDesIA9JOGj8bTG5P8Meta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "energy-cdr-thank-you",
    path: "/energy/cdr/thank-you",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/cdr/thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexqV2__45UFSnb0gUa8IHuiCJoPFIYbjqKF68V0vsPe6FCcMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: index3qqB85IYMhooVRm8Zh4rDIYJqH0Xkd6TRbAWUOmVTygMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexk3mBVeUmZghLtj2H_EItHhzzP5cM7RVFSTGmvRxnf7sMeta?.props ?? {"verificationNeeded":true},
    meta: indexk3mBVeUmZghLtj2H_EItHhzzP5cM7RVFSTGmvRxnf7sMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsFT82KTdDxAt6NUdhJtgu7wt104KFN8PGBKZVVz6vCXQMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexb_455FBGrqYGdPFBswpaURc_45RgW5yCbBWcs4OA3D40IP4Meta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackoGAN_45nr_450HzCuzquPl4OYwf29FQpWgaVTRaW7A_VZ0wMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-callback",
    path: "/life-insurance/quote/callback",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/callback.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-comparison",
    path: "/life-insurance/quote/comparison",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/comparison.vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebate6wN2uQyBWJz26reBgUZZikHoZ5LBvnNZSm9__450U_3N4Meta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: details81zDTtR6Lg6FHLT0ysuKqVNTps7qhpyfBrUPUnmMDX0Meta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-applicationV2-step",
    path: "/health-insurance/applicationV2/:step()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offereg6cyPRsSLVilKVqpZup1qciBUWzqvRC_s6mpDNlm8kMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsZPROAgIwQ_w5TlHiU8nk9Vjc_45_45SSXHAOmPsAvwMJIGoMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93UImFYwmGxTtNN_RCQNASfUivRCl_45bjniTMIPonaav8kMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "life-insurance-quote-application-thank-you",
    path: "/life-insurance/quote/application/thank-you",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-quote-application-pre-assesment",
    path: "/life-insurance/quote/application/pre-assesment",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionXp8lAuN0EsPSTY6fmPTY7tc5vXRJkgRaUsY_WRuYdJAMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93FYVftT_45__h_XBRaJHJZP9qZjKL2haacF9k8iw8mxY7IMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93_zfQE4jjBBNdic6ENMBUESKrLWWIbtXvERudfKaWtCYMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_932enlsdn6zTv7Hl1zL9lq6SNizhdZ2463nt_zmgCp8GEMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailsD7q4FgZYpCNri2ys1ZadGoy8q3QOcntnQj_KBQzMqP8Meta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93M32FDKPnhi9PajSphId3GUIG0Qpzsok5lOEanp13PsoMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_930Vvgda1W4O_Ieg4zLttUveCJ6nNcURZyEW4gAE4a4PIMeta || {},
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-budget-hub-health-insurance-average-cost-calculator",
    path: "/health-insurance/budget-hub/health-insurance-average-cost-calculator",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src2375469314/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]